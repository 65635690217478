import { Col, Form } from "react-bootstrap"
import { $CommonServiceFn } from "../network/Service"
import konsole from "../control/Konsole"

{/*   ----***--------***---- Input Box Component ----***--------***---- */ }

export const InputCom = ({ xs = 12, sm = 12, md = 4, lg = 4, label, id, placeholder, name, type, value = '', onChange, disable, onBlur }) => {
  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} className="mb-2">
      {/* <Form.Label className="mb-1">{label}</Form.Label> */}
      <Form.Control type={type} id={id} placeholder={placeholder} name={name} value={value} onBlur={onBlur} onChange={onChange} className='custom-input' disabled={disable} />
    </Col>
  )
}

{/*   ----***--------***---- Select Component ----***--------***---- */ }

export const SelectCom = ({ sm = 4, lg = 4, label, id, isLabel, disabled = false, placeholder, options, value = '', onChange }) => {
  return (
    <Col xs sm={sm} lg={lg} className="mb-2">
      {(isLabel) && isLabel}
      <Form.Select aria-label="Default select example" disabled={disabled} id={id} value={value} onChange={onChange} className='custom-input'>
        <option value="" disabled selected>{placeholder}</option>
        {options?.map((item, index) => (<option key={index} value={item.value}>  {item.label} </option>))}
      </Form.Select>
    </Col>
  )
}


{/*   ----***--------***---- get api call with async ----***--------***---- */ }

export const getApiCall = (method, url, setState) => {
  return new Promise((resolve, reject) => {
    $CommonServiceFn.InvokeContractApi(method, url, '', (res, err) => {
      if (res) {
        konsole.log('res of fetching', url, 'data');
        if (setState) {
          setState(res?.data?.data);
        }
        resolve(res?.data?.data);
      } else {
        konsole.log('res in fetching', url, err);
        if (setState) {
          setState([]);
        }
        resolve('err');
      }
    });
  });
}

{/*   ----***--------***---- post api call with async ----***--------***---- */ }

export const postApiCall = (method, url, jsonObj) => {
  return new Promise((resolve, reject) => {
    $CommonServiceFn.InvokeCommonApi(method, url, jsonObj, (res, err) => {
      if (res) {
        konsole.log('res of saving', url, res);
        resolve(res)
      } else {
        konsole.log('res in saving', url, err);
        resolve('err')
      }
    })
  })
}


{/*   ----***--------***---- null || undefined ||'' ----***--------***---- */ }

export const isValidateNullUndefine = (value) => {
  if (value == 0 || value == undefined || value == null || value == '') {
    return true;
  }
  return false
}


{/*   ----***--------***---- null & undefine && ""----***--------***---- */ }

export const isNotValidNullUndefile = (value) => {
  if (value !== undefined && value !== null && value !== '') {
    return true;
  }
  return false
}