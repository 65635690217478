import axios from "axios";
import konsole from "../control/Konsole";
import { $Service_Url, Api_Url } from "./UrlPath";
// import Router from "next/router";
import { ABaseUrl } from "../control/Constant";


let $CommonServiceFn = {

    InvokeContractApi: function (method, url, inputdata, callback) {

        axios({
            headers: { 'Content-Type': 'application/json-patch+json' },
            config: { headers: { 'Content-Type': 'application/json-patch+json, application/x-www-form-urlencoded' } },
            method: method,
            url: url,
            data: (inputdata !== null) ? inputdata : null
        })
            .then(data => {
                konsole.log("URL-", url)
                if (callback) {
                    callback(data, null);
                }
            })
            .catch(error => {
                let errordata = null;
                konsole.log("Error ", error + url)
                konsole.log("Error ", error.response)
                if (error.response) {
                    errordata = error.response;
                    if (errordata.status == 401) {
                        let newWin = window.open(`${ABaseUrl}/ContractExpressAuth`, 'height=200,width=150');

                        if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
                            alert("please disable popup blocker to proceed.");
                        }
                        callback(null, errordata);
                    }
                    if (errordata.status !== 401 && errordata.status == 404) {
                        if (callback) {
                            callback(null, errordata);
                        }
                    }
                    if (errordata.status !== 401 && errordata.status == 400) {
                        if (callback) {
                            callback(null, errordata);
                        }
                    }
                    if (errordata.status !== 401 && errordata.status == 503) {
                        konsole.log("Something went Wrong. Try again later.");
                    }
                    if (errordata.status !== 401 && errordata.status == 500) {
                        callback(null, errordata);
                    }
                }

            });

    },
   
}










export {$CommonServiceFn };