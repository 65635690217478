import { ABaseUrl, CeURl } from "../control/Constant";

let BASE_URL = ABaseUrl;

let $Service_Url = {
  getFamilyMemberbyID: BASE_URL + "/api/Member/",
  getFamilyMembers: BASE_URL + "/api/Member/get-FamilyMembers-by-userid/",
  getcontactdeailswithOther:BASE_URL+ "/api/Contact/get-contact-by-userid/",
  getAllAddress: BASE_URL + "/api/Address/get-address-by-userid/",
  getUserInsurance: BASE_URL + "/api/UserInsurance/get-UserInsurance-by-userid/",
  getSearchProfessional: BASE_URL + '/api/2/ProfessionalUser/getMemberProfessionals',
  getBloodType:BASE_URL+'/api/BloodType',
  getEmergencyApi:BASE_URL+'/api/EmergencyCard/GetEmergencyCard',
  getEmergencyPriority:BASE_URL+'/api/EmerContactPriority/GetEmerContactPriority',
};



export let ipUrl = "https://geolocation-db.com/json";

export { $Service_Url };